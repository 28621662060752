@use '../variables/radiuses';
@use '../variables/shadows';
@use '../variables/colors';
@use '../abstracts/mixins';
@use '../modules/media';

$card-min-height: 615px;
$header-min-height: 200px;
$body-min-height: $card-min-height - $header-min-height;

.card2 {
  border-radius: radiuses.$card;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  //min-height: $card-min-height;
  position: relative;
  background-color: colors.$white;
  box-shadow: shadows.$card;

  //&.is-matching {
  //  min-height: $card-min-height + 40px;
  //}

  @include media.query('tablet-down') {
    max-width: 370px;
  }

  @include media.query('tablet-up') {
    max-width: 340px;
  }

  &__matching {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
  }

  &__matching-avatar {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
  }

  &__matching-label {
    font-size: 10px;
    margin-left: 10px;
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: $header-min-height;
    z-index: 100;
  }

  &__body {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    position: relative;
    margin-top: $header-min-height;
    width: 100%;
    //min-height: $body-min-height;
    z-index: 200;
    background-color: colors.$white;
    will-change: min-height;
    transition: margin-top 300ms ease;

    &.is-expanded {
      margin-top: $header-min-height - 110px;
    }

    .is-matching & {
      min-height: $body-min-height + 40px;

      &.is-expanded {
        min-height: 565px;
      }
    }
  }

  &__hero {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: white;
    background-color: rgba(#000, 0.3);
  }

  &__play-button {
    display: block;
    width: 100px;
    height: 100px;
  }

  &__avatar-wrapper {
    position: absolute;
    top: -44px;
    left: 20px;
    border-radius: 100%;
    width: 88px;
    height: 88px;
    overflow: hidden;
  }

  &__avatar {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    border: 2px solid white;
  }

  &__main {
    padding: 15px 20px;
  }

  &__share-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }

  &__share {
    position: relative;
    margin-left: auto;
    border: 1px solid colors.$primary;
    border-radius: 100px;
    overflow: hidden;
    // Fix for safari round borders bug
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  }

  &__share-links {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    transition: transform 400ms ease-in-out;
    transform: translateX(100%);
    will-change: transform;

    .is-opened & {
      transform: translateX(0);
    }
  }

  &__share-notification {
    display: block;
    position: absolute;
    top: calc(100% + 7px);
    right: 2px;
    color: white;
    background-color: colors.$primary;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    z-index: 10;
    border-radius: 5px;
    pointer-events: none;
    transition: opacity 200ms ease, transform 200ms ease;
    transform: translateY(-2px);
    opacity: 0;
    will-change: opacity, transform;

    &--creator {
      top: calc(100% + 10px);
      right: auto;
      left: 28px;
    }

    &.is-shown {
      opacity: 1;
      transform: translateY(0);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #363740 transparent;
    }
  }

  &__share-link {
    display: block;
    width: 20px;
    height: 20px;
    text-decoration: none;
    transition: opacity 200ms ease, transform 400ms ease;
    will-change: opacity, transform;
    transform: translateX(50px);
    cursor: pointer;

    .is-opened & {
      transform: translateX(0) !important;
    }

    &:hover {
      opacity: 0.7;
    }

    & + & {
      margin-left: 5px;
    }

    &:first-child {
      transition-delay: 0ms, 200ms;
    }

    &:nth-child(2) {
      transition-delay: 0ms, 250ms;
    }

    &:nth-child(3) {
      transition-delay: 0ms, 300ms;
    }

    &:nth-child(4) {
      transition-delay: 0ms, 350ms;
    }

    &:nth-child(5) {
      transition-delay: 0ms, 400ms;
    }
  }

  &__share-label {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    will-change: transform;
    transition: transform 400ms ease-in-out;

    .is-opened & {
      pointer-events: none;
      transform: translateY(-100%);
    }
  }

  &__share-icon {
    margin-right: 5px;
  }

  &__share-title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }

  &__content {
    padding-top: 15px;
  }

  &__title {
    @include mixins.font('Futura Std');

    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  &__social {
    display: flex;
    margin-top: 2px;
  }

  &__social-link {
    text-decoration: none;
    color: inherit;
    width: 14px;
    height: 14px;
    display: block;
    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.7;
    }

    & + & {
      margin-left: 10px;
    }
  }

  &__social-icon {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__description {
    margin-top: 10px;
    font-size: 13px;
    line-height: 18px;
    word-wrap: break-word;
    overflow: hidden;
    min-height: 60px;

    &--large {
      font-size: 15px;
      line-height: 21px;
    }
  }

  &__description-text {
    max-height: 180px;
    overflow-y: auto;

    .is-expanded & {
      display: block;
      padding-right: 10px;
      max-height: none;
    }
  }

  &__read-more {
    cursor: pointer;
  }

  &__payment-wrapper {
    //position: absolute;
    //bottom: 15px;
    //left: 20px;
    //width: calc(100% - 40px);
    position: relative;
    margin-top: 30px;
    padding: 20px 10px;
    box-shadow: shadows.$payment;
    border: 1px solid rgba(54, 55, 64, 0.05);
    border-radius: radiuses.$modal;
    z-index: 10;
    background-color: colors.$white;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;

    &.is-opened {
      pointer-events: initial;
    }
  }

  &__modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#240E2E, 0.80);
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity 300ms ease;

    .is-opened & {
      pointer-events: initial;
      opacity: 1;
    }
  }

  &__modal-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
    transform: translateY(100%);
    pointer-events: none;
    will-change: transform;
    transition: transform 300ms ease;

    .is-opened & {
      transform: translateY(0);
      pointer-events: initial;
    }
  }

  &__modal-content {
    padding: 20px;
    overflow-y: auto;
    height: 400px;
  }

  &__modal-header {
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 14px 15px;
  }

  &__modal-close {
    @include mixins.button-reset;

    display: flex;
    margin-left: auto;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
  }
}

.card {
  border-radius: radiuses.$card;
  box-shadow: shadows.$card;
  display: block;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  background-color: colors.$white;
  position: relative;
  overflow: hidden;

  &__alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    padding: 10px;
    background-color: colors.$success;
    color: colors.$white;
  }

  &__popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$white;
    border-radius: radiuses.$card;
    padding: 40px 15px 50px;
  }

  &__popup-title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }

  &__popup-close {
    @include mixins.button-reset;

    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    width: 23px;
    height: 23px;
    padding: 5px;
  }

  &__popup-description {
    //@include mixins.font('Futura Std');

    font-size: 16px;
    line-height: 20px;
    color: colors.$secondary-dark;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__payment-form {
    margin-top: 20px;
  }

  &__body {
    padding: 40px 15px 50px;

    &--tall {
      min-height: 700px;
    }
  }

  &__avatar-wrapper {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 120px;
    height: 120px;
  }

  &__avatar-poller {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    &--square {
      border-radius: 0;
    }
  }

  &__avatar {
    display: block;
    margin: 0 auto;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 100%;

    &--small {
      width: 80px;
      height: 80px;
    }
  }

  &__content {
    margin-top: 20px;
    text-align: center;
    padding: 0 30px;
  }

  &__title {
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  &__payment {
    margin-top: 30px;
  }

  &__label {
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }

  &__actions {
    display: grid;
  }

  &__actions-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;
    grid-row: 1;
    grid-column: 1;
  }

  &__action {
    @include mixins.button-reset;

    border-radius: radiuses.$button;
    border: 1px solid colors.$primary;

    font-size: 13px;
    line-height: 40px;
    font-weight: 700;
    color: colors.$primary;
    transition: color 200ms ease, background-color 200ms ease;

    &.is-selected,
    &:hover {
      color: colors.$white;
      background-color: colors.$primary;
    }

    &[disabled] {
      opacity: 0.3;
      //pointer-events: none;
      cursor: not-allowed;

      &:hover {
        color: colors.$primary;
        background-color: transparent;
      }
    }
  }

  &__button {
    @include mixins.button-reset;
    @include mixins.font('Futura Std');

    background-color: colors.$primary;
    color: colors.$white;
    border-radius: radiuses.$button;

    font-size: 16px;
    line-height: 22px;
    font-weight: 700;

    //display: grid;
    //grid-template-areas: 'icon text empty';
    //grid-template-columns: 25px 1fr 25px;
    width: 100%;
    padding: 0 25px;
    margin-top: 15px;
    transition: background-color 200ms ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: colors.$primary-dark;
    }

    &[disabled] {
      opacity: 0.3;
      //pointer-events: none;
      cursor: not-allowed;
    }

    &--outline {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      & .card__button-icon {
        fill: colors.$primary;
        margin-right: 5px;
      }

      &:hover .card__button-icon {
        fill: colors.$white;
      }
    }
  }

  &__button-icon-wrapper {
    //grid-area: icon;
    width: 25px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
  }

  &__button-icon {
    display: block;
    width: 25px;
    height: auto;
    transition: fill 200ms ease;
  }

  &__button-text {
    display: block;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
  }

  &__button-matching {
    font-size: 11px;
    line-height: 11px;
  }

  &__input-wrapper {
    grid-row: 1;
    grid-column: 1;
    position: relative;
    opacity: 1;
  }

  &__input {
    display: block;
    width: 100%;
    border-radius: radiuses.$button;
    border: 1px solid colors.$primary;
    background-color: colors.$white;
    font-size: 13px;
    line-height: 40px;
    font-weight: 700;
    color: colors.$primary;
    padding: 0 90px 0 32px;
  }

  &__input-prefix {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 42px;
    font-weight: 700;
    padding-left: 25px;
    color: colors.$primary;
  }

  &__input-suffix {
    @include mixins.button-reset;

    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    line-height: 42px;
    font-weight: 700;
    padding-right: 25px;
    color: colors.$danger;
  }

  &__social {
    //padding: 0 20px;
    margin-top: 30px;
  }

  &__social-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: inherit;
    text-decoration: none;

    & + & {
      margin-top: 25px;
    }
  }

  &__social-icon {
    display: inline-flex;
    margin-right: 10px;
    width: 30px;
    justify-content: center;
  }

  &__social-label {
    font-weight: 500;
    display: flex;
    width: 100%;
  }

  &__social-input-wrapper {
    display: flex;
    max-width: 100%;
    width: 100%;
  }

  &__social-input {
    //visibility: hidden;
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}
