@use 'sass:map';
@use 'sass:string';
@use 'sass:meta';

@use '../../console';

$-breakpoints: () !default;
$-queries: () !default;

@mixin config($breakpoints: null, $queries: null) {
  @if $breakpoints {
    $-breakpoints: $breakpoints !global;
  }

  @if $queries {
    $-queries: $queries !global;
  }
}

@mixin -context($breakpoints, $queries) {
  @content;
}

@function -get-query($name) {
  @if not map.has-key($-queries, $name) {
    $-e: console.error(
      '@media.query | $value: #{$name}',
      '               ^^^^^',
      'Error: Unknown query "#{string.unquote($name)}". Available queries: #{map.keys($-queries)}',
    );

    @return $-e;
  }

  @return map.get($-queries, $name);
}

@function -get-rule($query, $name) {
  @if not map.has-key($query, 'rule') {
    $-e: console.error(
        '@media.query | $value: #{$name}',
        '               ^^^^^',
        'Error: Query is missing ',
    );

    @return $-e;
  }

  @return map.get($query, 'rule');
}

@mixin -query($value) {
  //$rule: null;
  //
  //@if meta.type-of($value) == string {
  //  $rule: -get-rule(-get-query($value), $value);
  //} @else if meta.type-of($value) == list {
  //  $rule: $value;
  //} @else {
  //  // TODO: Throw error.
  //  @include console.error(
  //    '@media.query | $value: #{$value}',
  //    '               ^^^^^^',
  //    'Error: Type "#{meta.type-of($value)}" is not assignable to type "string | list".',
  //  );
  //}
  //
  //@content;

  @if $value == 'tablet-down' {
    @media screen and (max-width: 767px) {
      @content
    }
  }

  @if $value == 'tablet-up' {
    @media screen and (min-width: 768px) {
      @content
    }
  }
}

@mixin make($name, $args...) {
  @if $name == 'context' { @include -context($args...) { @content } }
  @if $name == 'query' { @include -query($args...) { @content } }
}
