@use '../modules/media';

.section {
  @include media.query('tablet-down') {
    padding: 35px 0;
  }

  @include media.query('tablet-up') {
    padding: 80px 0;
  }
}
