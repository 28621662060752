@use '../variables/fonts';
@use '../variables/colors';
@use '../abstracts/mixins';
@use '../modules/media';

.shared-card-page {
  padding: 0 20px;
  margin-top: -33px;

  &__collection {
    display: grid;
    grid-template-columns: 72px 1fr;
    grid-gap: 20px;
  }

  &__logo {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__description {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
  }

  &__description-matching {
    margin-top: 10px;
  }
}

.shared-card {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  max-width: 335px;

  &__body {
    background-color: white;
    padding: 20px;
  }

  &__button-prev {
    display: block;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    position: absolute;
    top: calc(50% - 10px);
    left: 20px;
    transform: rotate(45deg) translateY(-50%);
    pointer-events: none;
    opacity: 0.9;
  }

  &__button-next {
    opacity: 0.9;
    display: block;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    border-bottom: 4px solid white;
    border-right: 4px solid white;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    transform: rotate(-45deg) translateY(-50%);
    pointer-events: none;
  }

  &__title {
    @include mixins.font(fonts.$secondary);

    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__media {
    height: 420px;
    position: relative;
  }

  &__slider-wrapper {
    height: 420px;
    position: relative;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__slider {
    height: 100%;
  }

  &__slider-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__avatar-wrapper {
    position: relative;
    padding-top: 10px;
  }

  &__avatar {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 88px;
    height: 88px;
    border-radius: 100%;
    z-index: 10;
    border: 5px solid white;
    background: white;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    will-change: opacity;
    transition: opacity 200ms ease;
    z-index: 10;

    &.is-hidden {
      opacity: 0;

      & * {
        pointer-events: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 205px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__play-button {
    @include mixins.button-reset;

    position: absolute;
    top: calc(50% + 7px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    pointer-events: initial;
  }

  &__pagination-wrapper {
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
