@use '../abstracts/mixins';
@use '../variables/fonts';
@use '../variables/colors';
@use '../modules/media';

.cta-links {
  width: 100%;

  @include media.query('tablet-up') {
    padding-top: 85px;
    position: relative;
  }

  &::before {
    @include media.query('tablet-up') {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 3px;
      background-color: colors.$black;
    }
  }

  &__content-wrapper {
    display: grid;
    margin-top: 25px;

    @include media.query('tablet-up') {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__content {
    max-width: 440px;

    @include media.query('tablet-down') {
      margin: 0 auto;
    }
  }

  &__button {
    margin-top: 30px;

    @include media.query('tablet-down') {
      padding-left: 30px;
      padding-right: 30px;
      display: block;
      width: 100%;
    }
  }

  &__image {
    display: block;
    max-width: 52px;
    width: 100%;
    height: auto;

    @include media.query('tablet-down') {
      margin: 0 auto;
    }
  }

  &__links-wrapper {
    @include media.query('tablet-up') {
      margin-left: auto;
    }
  }

  &__links {
    padding: 0;
    list-style-type: none;
    display: grid;

    @include media.query('tablet-down') {
      gap: 30px;
      margin-top: 30px;
    }

    @include media.query('tablet-up') {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
  }

  &__link-item {
    @include media.query('tablet-down') {
      text-align: center;
    }
  }

  &__link {
    @include mixins.font(fonts.$primary);

    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  &__title {
    @include media.query('tablet-down') {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }

    @include media.query('tablet-up') {
      font-size: 34px;
      line-height: 50px;
    }
  }

  &__meta {
    @include mixins.font(fonts.$primary);

    font-size: 14px;
    line-height: 16px;

    @include media.query('tablet-up') {
      margin-top: 80px;
    }

    @include media.query('tablet-down') {
      text-align: center;
      margin-top: 30px;
    }
  }
}
