@use '../variables/colors';
@use '../modules/media';

.page {
  padding: 54px 24px 0;
  min-height: 100vh;

  &--no-padding-top {
    padding-top: 0;
    margin-top: -30px;
  }

  &__logo {
    display: block;
    max-width: 42px;
    width: 100%;

    @include media.query('tablet-down') {
      margin: 0 auto 24px;
    }

    @include media.query('tablet-up') {
      margin: 0 auto 48px;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;

    color: colors.$black;
  }

  &__subtitle {
    margin: 10px 0 -25px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;

    color: colors.$black;
  }

  &__form {
    max-width: 375px;
    width: 100%;

    @include media.query('tablet-down') {
      margin: 35px auto 0;
    }

    @include media.query('tablet-up') {
      margin: 65px auto 0;
    }

    &--disabled {
      position: relative;
      pointer-events: none !important;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(colors.$white, 0.5);
        z-index: 1;
      }
    }
  }

  &__description {
    max-width: 320px;
    margin: 24px auto 0;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
  }
}
