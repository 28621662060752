@use '../variables/colors';
@use '../variables/radiuses';
@use '../abstracts/mixins';

.alert {
  display: grid;
  grid-template-areas: 'title action' 'content content';
  gap: 20px;
  grid-template-columns: 1fr 13px;
  width: 100%;
  padding: 25px 30px;
  background-color: colors.$primary-light;
  border: 1px solid colors.$primary-light-shade;
  border-radius: radiuses.$alert;

  &--small {
    gap: 0;
    padding: 10px 15px;

    & + .alert--small {
      margin-top: 15px;
    }
  }

  &--danger {
    background-color: colors.$danger-light;
    border: 1px solid colors.$danger;

    .alert__title {
      color: colors.$danger;
    }
  }

  &--warning {
    background-color: #fff7e4;
    border: 1px solid #fff1d0;

    .alert__title {
      color: #b69f5f;
    }
  }

  &__close {
    @include mixins.button-reset;

    display: block;
  }

  &__close-icon {
    width: 13px;
    height: 13px;
  }

  &__title {
    grid-area: title;
    margin: 0;

    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: colors.$primary;
  }

  &__action {
    grid-area: action;
  }

  &__content {
    grid-area: content;

    font-size: 14px;
    line-height: 20px;
    color: colors.$secondary-semi-dark;
  }

  &__link {
    font-weight: 700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
