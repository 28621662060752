@use '../modules/media';

.main {
  @include media.query('tablet-down') {
    padding-top: 63px;
  }

  @include media.query('tablet-up') {
    padding-top: 72px;
  }
}
