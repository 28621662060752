@use '../modules/media';

.testimonial {
  width: 100%;
  display: grid;

  @include media.query('tablet-down') {
    grid-template-columns: 0.25fr 0.75fr;
    grid-template-rows: 0.2fr 1fr 1fr;
  }

  @include media.query('tablet-up') {
    grid-template-columns: 5.5fr 1fr 2.5fr;
    grid-template-rows: 90px 1fr 1fr 50px;
  }

  &__content-wrapper {
    background: linear-gradient(0deg, rgba(247,234,252,1) 0%, rgba(255,243,232,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    @include media.query('tablet-down') {
      grid-row: 2 / 4;
      grid-column: 1 / 3;
      padding: calc(75% + 50px) 50px 80px;
    }

    @include media.query('tablet-up') {
      grid-row: 2 / 4;
      grid-column: 1 / 3;
      padding: 120px 21% 120px 14%;
    }
  }

  &__content {
    margin: auto;
  }

  &__image-wrapper {
    @include media.query('tablet-down') {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }

    @include media.query('tablet-up') {
      grid-row: 1 / 3;
      grid-column: 2 / 4;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__text {
    font-weight: 500;

    @include media.query('tablet-down') {
      font-size: 26px;
      line-height: 40px;
    }

    @include media.query('tablet-up') {
      font-size: 32px;
      line-height: 60px;
    }
  }

  &__author {
    font-weight: 500;
    margin: 20px 0 0;

    @include media.query('tablet-down') {
      font-size: 20px;
      line-height: 30px;
    }

    @include media.query('tablet-up') {
      font-size: 22px;
      line-height: 60px;
    }
  }
}
