@use '../variables/colors';
@use '../modules/media';

.card-page {
  background-color: #faf7fb;
  min-height: 100vh;

  @include media.query('tablet-down') {
    padding: 15px 5px 5px;
    margin-top: -63px;
  }

  @include media.query('tablet-up') {
    padding: 50px 5px 5px;
    margin-top: -72px;
  }

  &__actions {
    max-width: 340px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 15px;
  }

  &__card {
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    &--white * {
      color: white !important;
    }
  }

  &__footer-links {
    display: flex;
    margin-top: 20px;

    @include media.query('tablet-down') {
      flex-direction: column;
    }
  }

  &__footer-link {
    text-decoration: none;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: #989898;
    text-align: center;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    & + & {
      @include media.query('tablet-down') {
        margin-top: 20px;
      }

      @include media.query('tablet-up') {
        margin-left: 40px;
      }
    }

    &:not(.card-page__footer-link--nested):hover {
      text-decoration: underline;
    }
  }

  &__footer-copyright {
    font-size: 13px;
    line-height: 20px;
    color: #989898;
    text-align: center;
    margin-top: 15px;
  }
}
