@use '../abstracts/mixins';
@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';
@use '../modules/media';

.image-cropper {
  align-items: center;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9010;
  display: none;

  &.is-opened {
    display: flex;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(colors.$black, 0.5);
  }

  &__body {
    position: relative;
    z-index: 1;
    max-width: 640px;
    width: 100%;
    border-radius: radiuses.$modal;
    box-shadow: shadows.$modal;
    background: colors.$white;
    overflow: hidden;

    //@include media.query('tablet-down') {
    //  margin: 16px;
    //}
    //
    //@include media.query('tablet-up') {
    //  margin: 24px;
    //}
  }

  &__content {
    padding: 30px;
  }

  &__image-wrapper {
    display: block;
    max-width: 100%;
    max-height: 500px;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
  }
}
