@use '../abstracts/mixins';
@use '../modules/media';

.advent {
  width: 100%;
  display: grid;

  @include media.query('tablet-down') {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media.query('tablet-up') {
    grid-template-columns: repeat(8, 1fr);
  }

  &--desktop {
    @include media.query('tablet-down') {
      display: none;
    }
  }

  &--mobile {
    @include media.query('tablet-up') {
      display: none;
    }
  }

  &__block {
    position: relative;
    display: grid;

    &::before {
      display: block;
      content: '';
      padding-bottom: 100%;
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  &__item {
    font-family: 'DM Serif Display', serif;
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    position: relative;

    &:hover .advent__link-door {
      transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
    }
  }

  &__link-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link-door {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #063a69;
    font-size: 48px;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left;
    transition: all 0.35s ease-in-out;
  }

  .background-yellow {
    background-color: #b7ba16;
  }

  .background-red {
    background-color: #c30000;
  }

  .background-green {
    background-color: #118216;
  }

  .triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--upper-left-yellow {
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      background-color: #b7ba16;
    }

    &--upper-right-yellow {
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      background-color: #b7ba16;
    }

    &--lower-left-yellow {
      clip-path: polygon(0 100%, 100% 100%, 0 0);
      background-color: #b7ba16;
    }

    &--lower-right-yellow {
      clip-path: polygon(0 100%, 100% 100%, 100% 0);
      background-color: #b7ba16;
    }

    &--upper-left-green {
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      background-color: #118216;
    }

    &--upper-right-green {
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      background-color: #118216;
    }

    &--lower-left-green {
      clip-path: polygon(0 100%, 100% 100%, 0 0);
      background-color: #118216;
    }

    &--lower-right-green {
      clip-path: polygon(0 100%, 100% 100%, 100% 0);
      background-color: #118216;
    }

    &--upper-left-red {
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      background-color: #c30000;
    }

    &--upper-right-red {
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      background-color: #c30000;
    }

    &--lower-left-red {
      clip-path: polygon(0 100%, 100% 100%, 0 0);
      background-color: #c30000;
    }

    &--lower-right-red {
      clip-path: polygon(0 100%, 100% 100%, 100% 0);
      background-color: #c30000;
    }
  }
}
