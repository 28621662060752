/* You can add global styles to this file, and also import other style files */
// Config
@use 'styles/config';

// Vendor
@use 'styles/vendor';

// Base
@use 'styles/base';

// Components
@use 'styles/components';
