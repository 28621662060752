@use '../variables/fonts';
@use '../variables/colors';
@use '../abstracts/mixins';
@use '../modules/media';

.text-image {
  display: grid;
  background: linear-gradient(0deg, #D38FAF 0%, #7667AB 100%);

  @include media.query('tablet-down') {
    grid-template-rows: 50px 1fr;
  }

  @include media.query('tablet-up') {
    grid-template-columns: 1fr 3.5fr 7.1fr 1fr;
    grid-template-rows: 100px 1fr 100px;
  }

  &__content-wrapper {
    @include media.query('tablet-down') {
      grid-column: 1;
      grid-row: 2 / 4;
    }

    @include media.query('tablet-up') {
      grid-column: 2 / 4;
      grid-row: 2 / 3;
    }
  }

  &__content {
    display: grid;

    @include media.query('tablet-down') {
      gap: 10px;
    }

    @include media.query('tablet-up') {
      grid-template-columns: 1.4fr 1fr;
      gap: 20px;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  &__text {
    color: colors.$white;
    margin: auto 0;

    @include media.query('tablet-down') {
      grid-row: -1;
      padding: 0 30px;
    }

    h2 {
      font-weight: 700;

      @include media.query('tablet-down') {
        font-size: 32px;
        line-height: 42px;
        text-align: center;
      }

      @include media.query('tablet-up') {
        font-size: 50px;
        line-height: 80px;
      }
    }

    p {
      @include mixins.font(fonts.$primary);

      margin-top: 15px;

      @include media.query('tablet-down') {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }

      @include media.query('tablet-up') {
        font-size: 20px;
        line-height: 34px;
      }
    }
  }

  &__background {
    position: relative;
    z-index: -1;

    @include media.query('tablet-down') {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    @include media.query('tablet-up') {
      grid-column: 3 / 5;
      grid-row: 1 / 4;
      margin: auto 0;
    }
  }

  &__shape {
    border-radius: 100%;
    background: linear-gradient(0deg, #D38FAF 0%, #7667AB 100%);

    @include media.query('tablet-down') {
      width: 640px;
      height: 640px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include media.query('tablet-up') {
      width: 1110px;
      height: 1110px;
    }
  }
}
