@use '../variables/colors';
@use '../abstracts/mixins';

.slide-layout {
  padding: 25px;
  min-height: 500px;
  max-height: 90vh;
  overflow-y: auto;

  &__image {
    display: block;
    max-width: 33px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  &__title {
    margin-top: 8px;

    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
  }

  &__footer {
    position: relative;
    display: grid;
    grid-template-areas: 'empty counter actions';
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 35px;
    z-index: 10;
    background-color: #FEFEFF;
  }

  &__counter {
    grid-area: counter;
    margin: auto;

    font-size: 14px;
    line-height: 28px;
    color: colors.$secondary-semi-dark;
  }

  &__actions {
    grid-area: actions;
    margin-left: auto;
  }

  &__body {
    display: grid;
    grid-template-areas: 'slide';
    min-height: 400px;
    padding-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__slide {
    grid-area: slide;
  }

  &__cover-wrapper {
    position: relative;
    cursor: pointer;
  }

  &__cover-holder {
    position: relative;
    width: 100%;
    padding-top: 125%;
  }

  &__cover {
    display: block;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__slide-title {
    margin: 0;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
  }

  &__slide-description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 0 40px;
    margin: 20px 0;
  }

  &__slide-image {
    display: block;
    margin: 15px auto 0;
  }

  &__prefixed-text {
    padding-left: 110px;
    padding-right: 52px;
  }

  &__button {
    margin-top: 15px;
  }

  &__avatar-wrapper {
    display: block;
    margin: 30px auto;
    width: 84px;
    height: 84px;
    position: relative;
    cursor: pointer;
    border-radius: 100%;
    border: 2px solid white;
  }

  &__avatar {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%;
  }

  &__form {
    .form-group + .form-group {
      margin-top: 10px;
    }
  }

  &__action-button {
    @include mixins.button-reset;

    position: absolute;
    bottom: 0;
    right: 10px;

    &--avatar {
      bottom: -15px;
      right: -10px;
    }
  }
}
