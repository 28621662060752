@use '../variables/colors';
@use '../variables/radiuses';
@use '../modules/media';

.dashboard {
  $this: &;

  margin: 0 auto;
  max-width: 810px;
  //max-height: calc(100vh - 102px);
  //overflow: hidden;

  @include media.query('tablet-up') {
    display: grid;
    grid-auto-flow: dense;
    grid-template-areas: 'sidebar widgets';
    grid-template-columns: minmax(0, 200px) minmax(0, 540px);
    gap: 10px;
    padding: 30px 25px 0;
  }

  &__sidebar {
    @include media.query('tablet-down') {
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      display: none;
    }

    @include media.query('tablet-up') {
      grid-area: sidebar;
    }

    &.is-opened {
      @include media.query('tablet-down') {
        display: block;
        z-index: 10;
      }
    }
  }

  &__wrapper {
    //max-height: calc(100vh - 102px);
    //overflow-y: auto;

    @include media.query('tablet-up') {
      grid-area: widgets;
    }
  }

  &__widgets {
    display: grid;
    gap: 20px;

    @include media.query('tablet-down') {
      padding: 0 25px 25px;
    }

    @include media.query('tablet-up') {
      padding: 0 30px 30px;
    }
  }

  &__navigation {
    position: -webkit-sticky; // Safari
    position: sticky;

    @include media.query('tablet-down') {
      top: 63px;
    }

    @include media.query('tablet-up') {
      top: 72px;
    }
  }

  &__menu {
    list-style-type: none;
    margin: 0;

    @include media.query('tablet-down') {
      padding: 30px 0;
    }

    @include media.query('tablet-up') {
      padding: 0;
    }
  }

  &__submenu {
    list-style-type: none;
    padding: 0 0 0 25px;
    margin: 10px 0 0;
  }

  &__menu-item {
    $menu-item: &;

    display: block;

    & + & {
      margin-top: 10px;
    }

    &:not(#{$menu-item}--active):hover #{$this}__menu-link {
      color: colors.$black;
    }

    &--active #{$this}__menu-link {
      font-weight: 500;
      background-color: colors.$primary-light;
      color: colors.$primary;
    }
  }

  &__submenu-item {
    display: block;

    & + & {
      margin-top: 5px;
    }
  }

  &__menu-link {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    padding: 6px 25px;
    border-radius: radiuses.$button-small;
    color: colors.$secondary-semi-dark;
    transition: color 200ms ease;
    cursor: pointer;

    @include media.query('tablet-down') {
      padding: 6px 50px;
    }

    @include media.query('tablet-up') {
      padding: 6px 25px;
    }
  }

  &__submenu-link {
    $submenu-link: &;

    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;

    position: relative;
    color: colors.$secondary-semi-dark;
    transition: color 200ms ease;

    @include media.query('tablet-down') {
      padding: 6px 42px;
    }

    @include media.query('tablet-up') {
      padding: 6px 17px;
    }

    &:not(#{$submenu-link}--active):hover {
      color: colors.$black;
    }

    &--active {
      font-weight: 500;
      color: colors.$secondary-dark;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 22px;
        background-color: colors.$secondary-dark;
        border-radius: 100px;

        @include media.query('tablet-down') {
          left: 25px;
        }

        @include media.query('tablet-up') {
          left: 0;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }
}
