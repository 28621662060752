@use '../modules/media';

.artvent {
  &__hero {
    background-color: #c30000;
    color: white;
    font-family: 'DM Sans', sans-serif;
  }

  &__newsletter {
    margin-top: 60px;
    font-weight: 700;
  }

  &__background {
    display: block;
    width: 100%;
    height: auto;

    @include media.query('tablet-up') {
      max-width: 800px;
    }

    &--desktop {
      @include media.query('tablet-down') {
        display: none;
      }
    }

    &--mobile {
      @include media.query('tablet-up') {
        display: none;
      }
    }
  }

  &__hero-subtitle {
    font-size: 22px;
    line-height: 24px;
  }

  &__hero-title {
    font-size: 46px;
    line-height: 70px;
  }

  &__content {
    margin-top: 60px;
  }
}
