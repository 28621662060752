@use '../modules/media';

.info-box {
  display: grid;

  grid-auto-flow: dense;
  gap: 10px 20px;

  @include media.query('tablet-down') {
    grid-template-areas: 'avatar' 'content' 'actions';
    grid-template-columns: 1fr;
  }

  @include media.query('tablet-up') {
    grid-template-areas: 'avatar content' '. actions';
    grid-template-columns: 80px 1fr;
  }

  &__avatar-wrapper {
    grid-area: avatar;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    position: relative;
  }

  &__avatar-poller {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__avatar {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;

    @include media.query('tablet-down') {
      margin: 0 auto;
    }
  }

  &__content {
    grid-area: content;
    width: 100%;
    margin: auto 0;

    @include media.query('tablet-down') {
      text-align: center;
    }
  }

  &__actions {
    grid-area: actions;
    position: relative;

    @include media.query('tablet-down') {
      text-align: center;
    }
  }

  &__action {
    position: relative;
    display: inline-block;

    & + & {
      margin-left: 10px;
    }
  }

  &__title {
    margin: 0;

    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin: 5px 0 0;
    border: none;
    padding: 0;
    background: none;
    display: block;
    width: 100%;

    @include media.query('tablet-down') {
      text-align: center;
    }
  }
}
