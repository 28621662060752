@use '../abstracts/mixins';
@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';
@use '../modules/media';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(colors.$black, 0.5);
  }

  &__body {
    position: relative;
    z-index: 1;
    max-width: 400px;
    width: 100%;
    border-radius: radiuses.$modal;
    box-shadow: shadows.$modal;
    background: colors.$white;
    overflow: hidden;

    @include media.query('tablet-down') {
      margin: 16px;
    }

    @include media.query('tablet-up') {
      margin: 24px;
    }

    @media (max-height: 750px) {
      height: 100%;
    }

    &--gradient-background {
      background: linear-gradient(180deg, #fcf5ff 0%, #fff 100%);
    }

    .slide-layout {
      min-height: auto;
    }
  }

  &__header {
    border-bottom: 1px solid colors.$secondary;
    display: grid;
    grid-template-areas: 'left-action title right-action';

    @include media.query('tablet-down') {
      padding: 25px;
    }

    @include media.query('tablet-up') {
      padding: 25px 30px;
    }
  }

  &__left-action {
    grid-area: left-action;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__title {
    grid-area: title;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: colors.$black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right-action {
    grid-area: right-action;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content {
    @include media.query('tablet-down') {
      padding: 30px 25px;
    }

    @include media.query('tablet-up') {
      padding: 40px 30px;
    }
  }

  &__button-action {
    @include mixins.button-reset;

    display: inline-block;
    width: 23px;
    height: 23px;
    padding: 5px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}

.step-1 {
  grid-area: content;
}

.step-2 {
  grid-area: content;
}
