@use '../variables/colors';
@use '../modules/media';

.card-preview {
  display: grid;
  width: 100%;
  grid-auto-flow: dense;

  @include media.query('tablet-down') {
    grid-template-rows: 0.15fr 0.7fr 0.3fr 0.7fr;
  }

  @include media.query('tablet-up') {
    grid-template-columns: 5fr 1fr 1.5fr;
  }

  &__url {
    grid-row: 1;
    grid-column: 1 / 2;
    padding: 20px 0;
    z-index: 1;

    @include media.query('tablet-down') {
      font-size: 20px;
      text-align: center;
      margin: auto 0;
    }

    @include media.query('tablet-up') {
      font-size: 24px;
    }

    a {
      font-weight: 700;
      color: colors.$primary;
    }
  }

  &__card {
    display: block;
    z-index: 1;

    @include media.query('tablet-down') {
      grid-row: 3 / 5;
      grid-column: 1;
      padding: 0 30px;
    }

    @include media.query('tablet-up') {
      grid-row: 1 / 3;
      grid-column: 2 / 4;
    }
  }

  &__card-image-wrapper {
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: linear-gradient(0deg, rgba(248,235,252,1) 0%, rgba(232,255,254,1) 100%);
      z-index: -1;

      @include media.query('tablet-down') {
        top: 5%;
        left: 6%;
      }

      @include media.query('tablet-up') {
        top: 9%;
        left: 14%;
      }
    }
  }

  &__card-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, 0.15);
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;

    @include media.query('tablet-down') {
      grid-row: 1 / 4;
      grid-column: 1;
      height: 100%;
    }

    @include media.query('tablet-up') {
      grid-row: 2;
      grid-column: 1 / 3;
      height: auto;
    }
  }
}
