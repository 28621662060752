@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';

.icon-group {
  $this: &;

  display: inline-grid;
  grid-template-areas: 'icon content';
  gap: 13px;

  &__icon-wrapper {
    grid-area: icon;
    max-width: 24px;
    width: 100%;

    #{$this}--large & {
      max-width: 36px;
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: auto;
  }

  &__content {
    grid-area: content;
    margin: auto 0;

    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;

    #{$this}--large & {
      font-size: 14px;
      font-weight: 400;
      text-transform: none;
    }

    strong {
      font-weight: 500;
    }
  }
}
