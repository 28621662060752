@use '../variables/colors';

.link {
  display: inline-block;
  cursor: pointer;

  font-weight: 500;
  color: colors.$primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
