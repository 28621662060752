@use '../abstracts/mixins';
@use '../variables/fonts';

.faq {
  margin-bottom: 60px;

  &__group {
    margin-top: 35px;
  }

  &__question {
    margin-top: 45px;

    &--condensed {
      margin-top: 15px;
    }
  }

  a {
    color: #642780;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3 {
    @include mixins.font(fonts.$secondary);
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 36px;
    line-height: 48px;
  }

  p,
  ul,
  ol {
    font-size: 20px;
    line-height: 34px;

    & + ul,
    & + ol,
    & + p, {
      margin-top: 20px;
    }
  }
}
