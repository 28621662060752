.canonical-card-page {
  padding-top: 50px;
  background: #fbf9fc;
  margin-top: -72px;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  &__card {
    max-width: 355px;
    margin: 0 auto;
  }
}
