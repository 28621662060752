@use '../variables/colors';
@use '../variables/fonts';
@use '../abstracts/mixins';
@use '../modules/media';

.title-list {
  width: 100%;
  display: grid;

  @include media.query('tablet-down') {
    grid-template-columns: 1fr;
    padding-top: 35px;
    grid-gap: 60px;
  }

  @include media.query('tablet-up') {
    grid-template-columns: 1fr 1fr;
    grid-gap: 115px;
  }

  &__title {
    font-weight: 700;

    @include media.query('tablet-down') {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    @include media.query('tablet-up') {
      font-size: 50px;
      line-height: 80px;
      text-align: right;
      max-width: 500px;
      margin-left: auto;
    }
  }

  &__list-items {
    padding: 0;
    list-style-type: none;

    @include media.query('tablet-down') {
      max-width: 200px;
      margin: 0 auto;
    }

    @include media.query('tablet-up') {
      max-width: 300px;
    }
  }

  &__list-item {
    @include mixins.font(fonts.$primary);


    position: relative;
    font-weight: 700;

    @include media.query('tablet-down') {
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }

    @include media.query('tablet-up') {
      font-size: 20px;
      line-height: 34px;
      padding-left: 50px;
    }

    & + & {
      margin-top: 30px;
    }

    &::before {
      @include mixins.font(fonts.$secondary);

      counter-increment: homepage-title-list;
      content: counter(homepage-title-list);
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
      color: colors.$accent;

      @include media.query('tablet-down') {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

      @include media.query('tablet-up') {
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
  }

  &__button {
    margin-top: 40px;

    @include media.query('tablet-down') {
      display: block;
      width: 100%;
    }
  }
}
