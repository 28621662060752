@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';
@use '../modules/media';

.button {
  background: none;

  display: inline-block;
  border-radius: radiuses.$button;
  padding: 6px 20px;

  font-size: 14px;
  line-height: 28px;
  font-weight: 500;

  color: colors.$primary;
  border: 1px solid colors.$primary;
  text-align: center;

  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition: all 200ms ease;

  strong {
    font-weight: 500;
  }

  &:hover {
    background: colors.$primary;
    color: colors.$white;
  }

  &--disabled,
  &[disabled] {
    opacity: 0.3;
    //pointer-events: none;
    cursor: not-allowed;
  }

  &--square {
    border-radius: radiuses.$button-small;
  }

  &--large {
    font-weight: 700;

    @include media.query('tablet-down') {
      padding: 15px 60px;
      font-size: 18px;
      line-height: 28px;
    }

    @include media.query('tablet-up') {
      padding: 20px 60px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &--primary {
    background: colors.$primary;
    color: colors.$white;

    &:hover {
      background: colors.$primary-dark;
      border-color: colors.$primary-dark;
    }
  }

  &--secondary {
    background: colors.$secondary-dark;
    border-color: colors.$secondary-dark;
    color: colors.$white;

    &:hover {
      background: colors.$black;
      border-color: colors.$black;
    }
  }

  &--link {
    border-color: colors.$white;
    padding: 6px 10px;

    &:hover {
      text-decoration: underline;
      background: colors.$white;
      color: colors.$primary;
    }
  }

  &--inline {
    padding: 0;
  }

  &--link-secondary {
    padding: 6px 10px;
    border-color: colors.$white;
    color: colors.$secondary-dark;

    &:hover {
      text-decoration: underline;
      background: colors.$white;
      color: colors.$secondary-dark;
    }
  }

  &--full {
    display: block;
    width: 100%;
  }
}
