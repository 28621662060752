@use '../abstracts/mixins';
@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';
@use '../modules/media';

$transition-duration: 400ms;

.card-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
  pointer-events: none;

  &.is-opened {
    pointer-events: initial;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(colors.$black, 0.5);
    z-index: 1;
    transition: opacity $transition-duration ease;
    opacity: 0;
    pointer-events: none;

    .is-opened & {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1111;
    background-color: colors.$white;
    border-top-left-radius: radiuses.$card;
    border-top-right-radius: radiuses.$card;
    transition: transform $transition-duration ease;
    transform: translateY(100%);
    pointer-events: none;

    .is-opened & {
      transform: translateY(0);
      pointer-events: initial;
    }
  }

  &__content {
    padding: 20px 20px 40px;
  }

  &__header {
    border-bottom: 1px solid colors.$secondary;
    display: grid;
    grid-template-areas: 'left-action title right-action';
    padding: 15px 15px 15px 28px;
  }

  &__left-action {
    grid-area: left-action;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__title {
    grid-area: title;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: colors.$black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right-action {
    grid-area: right-action;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
