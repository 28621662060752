@use '../abstracts/mixins';
@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';
@use '../modules/media';

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;

  &__content {
    text-align: center;
    display: block;
  }

  &__image {
    margin-bottom: 20px;
    transform: translateX(-12px);
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    color: colors.$primary;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 27px;
  }

  &__link {
    display: block;
    margin-top: 11px;
    font-size: 14px;
    line-height: 28px;
    color: colors.$primary;
  }
}
