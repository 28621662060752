.login-form {
  &__inputs {
    display: grid;
    gap: 15px;
  }

  &__actions {
    margin-top: 20px;
  }
}
