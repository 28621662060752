.user-form {
  &__inputs {
    display: grid;
    gap: 15px;
    grid-template-areas: 'i1 i2' 'i3 i3' 'i4 i4';

    .form-group {
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          grid-area: i#{$i};
        }
      }
    }
  }

  &__actions {
    margin-top: 20px;
  }
}
