@use 'functions';

@mixin button-reset {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

@mixin font($fonts...) {
  font-family: functions.make-font-stack($fonts...);
}
