@use '../variables/colors';
@use '../variables/shadows';
@use '../variables/radiuses';
@use '../modules/media';

.panel {
  $this: &;

  display: block;
  border: 1px solid colors.$secondary;
  border-radius: radiuses.$panel;
  box-shadow: shadows.$panel;

  &--disabled {
    opacity: 0.4;
    cursor: not-allowed;
    //pointer-events: none !important;
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  &__header {
    border-bottom: 1px solid colors.$secondary;
    padding: 30px;
  }

  &__footer {
    border-top: 1px solid colors.$secondary;
    padding: 30px;
  }

  &__body {
    @include media.query('tablet-down') {
      padding: 25px 30px;
    }

    @include media.query('tablet-up') {
      padding: 35px 40px;
    }

    #{$this}--narrow & {
      padding: 40px 30px;
    }

    #{$this}--transparent & {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__actions {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__content {
    &--centered {
      text-align: center;
    }

    h2 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      margin: 18px 0;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin: 18px 0;
    }
  }

  &__table {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-top: 30px;

    td {
      padding: 5px 0;
    }
  }
}
