@use '../modules/media';

.container {
  margin: 0 auto;
  max-width: 940px; // 940 + 2*30 = 1000px
  width: 100%;
  padding: 0 30px;

  &--mobile-full {
    @include media.query('tablet-down') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--small {
    max-width: 810px;
  }
}
