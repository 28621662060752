@use '../abstracts/mixins';
@use '../modules/media';

.social-links {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @include media.query('tablet-down') {
    justify-content: center;
  }

  &__item {
    display: block;
    width: 22px;
    height: 22px;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.7;
    }

    & + & {
      margin-left: 22px;
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
  }
}
