// Private
$-purple: #642780;
$-purple-dark: #541b67;
$-purple-light-shade: #efdcf6;
$-purple-light: #faf3fc;
$-purple-lighter: #faf7fb;
$-orange: #ff891d;
$-orange-light: #f3aa3b;
$-green: #8cbd24;
$-red: #da3019;
$-red-light: #ffebea;
$-pink: #f0d3fb;

// Number denotes lightness component of the color.
$-gray-23: #363740;
$-gray-36: #5b5b5b;
$-gray-60: #989898;
$-gray-68: #aeaeae;
$-gray-90: #e5e5e5;
$-gray-97: #f8f8f8;

// Public
$black: #000;
$white: #fff;

$primary: $-purple;
$primary-dark: $-purple-dark;
$primary-light: $-purple-light;
$primary-light-shade: $-purple-light-shade;
$primary-lighter: $-purple-lighter;

$secondary: $-gray-90;
$secondary-semi-dark: $-gray-36;
$secondary-dark: $-gray-23;
$secondary-shade: $-gray-60;
$secondary-light: $-gray-68;
$secondary-lighter: $-gray-97;

$accent: $-pink;

$success: $-green;
$danger: $-red;
$danger-light: $-red-light;
