@use '../variables/colors';
@use '../variables/fonts';
@use '../abstracts/mixins';
@use '../modules/media';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-weight: 700;

  z-index: 6666;
  background-color: colors.$white;

  @include media.query('tablet-down') {
    border-bottom: 1px solid colors.$secondary;
    padding: 10px 30px;
  }

  @include media.query('tablet-up') {
    padding: 15px 30px;
  }

  .container--small {
    @include media.query('tablet-down') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__brand {
    display: flex;
    align-items: flex-end;
    color: inherit;
    text-decoration: none;
  }

  &__logo {
    display: block;
    width: 100%;
    height: auto;

    &--desktop {
      max-width: 300px;

      @include media.query('tablet-down') {
        display: none;
      }

      @include media.query('tablet-up') {
        display: block;
      }
    }

    &--mobile {
      max-width: 36px;

      @include media.query('tablet-down') {
        display: block;
      }

      @include media.query('tablet-up') {
        display: none;
      }
    }
  }

  &__brand-text {
    font-size: 20px;
    line-height: 20px;
    margin-left: 10px;
  }

  &__menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__menu-item {
    @include mixins.font(fonts.$secondary);

    & + & {
      margin-left: 10px;
    }

    .button {
      font-weight: 700 !important;
    }
  }

  &__hamburger {
    @include mixins.button-reset;

    @include media.query('tablet-up') {
      display: none;
    }
  }
}
