@use '../variables/colors';

.social-login {
  &__items {
    list-style-type: none;
    display: grid;
    padding: 0 10px;
    margin: 0;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    margin: 0 auto;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 73px;
    height: 73px;
    border-radius: 100%;
    border: 1px solid colors.$secondary-shade;
    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.5;
    }
  }

  &__delimiter {
    display: block;
    position: relative;

    text-align: center;
    font-size: 14px;
    line-height: 28px;
    padding: 12px 0;
    color: colors.$secondary-shade;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: calc(50% - 30px);
      background-color: colors.$secondary-light;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}
