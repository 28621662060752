@use '../abstracts/mixins';
@use '../variables/fonts';
@use '../variables/colors';
@use '../modules/media';

@keyframes fade-in-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hero {
  &__title {
    font-weight: 700;
    text-align: center;

    @include media.query('tablet-down') {
      font-size: 32px;
      line-height: 40px;
    }

    @include media.query('tablet-up') {
      font-size: 46px;
      line-height: 70px;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__small-title {
    font-weight: 700;
    text-align: center;
    margin-top: 20px;

    @include media.query('tablet-down') {
      font-size: 20px;
      line-height: 32px;
    }

    @include media.query('tablet-up') {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &__subtitle {
    @include mixins.font(fonts.$primary);

    text-align: center;
    margin-top: 20px;

    @include media.query('tablet-down') {
      font-size: 16px;
      line-height: 24px;
    }

    @include media.query('tablet-up') {
      font-size: 20px;
      line-height: 40px;
    }
  }

  &__animation {
    display: flex;
    width: 100%;

    @include media.query('tablet-down') {
      margin-top: 20px;
      flex-wrap: wrap;
    }

    @include media.query('tablet-up') {
      margin-top: 45px;
    }
  }

  &__animation-input {
    @include mixins.font(fonts.$primary);

    flex: 1;
    background-color: #f0f0f0;
    border-radius: 100px;
    align-items: center;

    @include media.query('tablet-down') {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 65px;
      padding: 0 30px;
    }

    @include media.query('tablet-up') {
      display: flex;
      font-size: 28px;
      line-height: 28px;
      padding: 0 55px;
    }
  }

  &__animation-label {
    font-weight: 700;
    color: colors.$primary;
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      width: 2px;
      height: 28px;
      position: absolute;
      right: -4px;
      top: calc(50% + 1px);
      transform: translateY(-50%);
      background-color: black;
      animation: fade-in-out 400ms linear infinite alternate;
    }
  }

  &__button {
    @include media.query('tablet-down') {
      display: block;
      width: 100%;
    }

    @include media.query('tablet-up') {
      margin-left: 25px;
      line-height: 50px;
    }
  }
}
