@use '../variables/colors';

.bank-form {
  &--disabled {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(colors.$white, 0.5);
    }
  }

  &__inputs {
    display: grid;
    gap: 15px;
    //grid-template-areas: 'i1 i1' 'i2 i3' 'i4 i4';

    //.form-group {
    //  @for $i from 1 through 6 {
    //    &:nth-child(#{$i}) {
    //      grid-area: i#{$i};
    //    }
    //  }
    //}
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
  }

  &__errors {
    margin-bottom: 15px;
  }
}
